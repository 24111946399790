import React from 'react';

function AppBar(){
	return (
		<div className='appBar'>

			<span className='material-symbols-outlined appBarIcon'>

				eco

			</span>

			<h1> MongoChat </h1>

		</div>
	);
}

export default AppBar;