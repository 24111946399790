import React, {useState, useEffect} from 'react';
import io from 'socket.io-client';
import notify from './notify';
import AppBar from './AppBar';
import NameModal from './NameModal';
import ChatBox from './ChatBox';
import './App.css';

const socket = io('https://mongo-chat-express.glitch.me');

function App(){
    const [username, setUsername] = useState('');

    const [messages, setMessages] = useState([]);

    useEffect(() => {
        socket.on('allMessages', (allMessages) => {
            setMessages(allMessages);
        });

        socket.on('newMessage', (data) => {
            let newData;

            if (data.id === socket.id){
                newData = {
                    sender: 'user',
                    ...data
                }
            }
            else{
                newData = {
                    sender: 'other',
                    ...data
                }
            }

            setMessages((pastMessages) => {
                const newMessages = [...pastMessages, newData];

                return newMessages;
            });
        });

        socket.on('messageLimit', () => {
            notify('Please wait before sending another message!' , 2000);
        });
    }, []);

    return (
        <>
            <AppBar/>
            <NameModal setUsername={setUsername}/>
            <ChatBox username={username} socket={socket} messages={messages}/>
        </>
    );
}

export default App;