import React, {useRef} from 'react';

function NameModal({setUsername}){
	const usernameRef = useRef();

	const modalRef = useRef();

	function handleClick(){
		const username = usernameRef.current.value.substring(0, 15);

		if (username){
			setUsername(username.substring(0, 15));
		}
		else{
			setUsername('UnknownUser');
		}

		modalRef.current.remove();
	}

	return (
		<div className='modalContainer' ref={modalRef}>

			<div className='modal'>

				<h3>

					Please enter your username:

				</h3>

				<input className='usernameInput' placeholder='Username' ref={usernameRef} maxLength='15'/>

				<div className='usernameButton' onClick={() => {handleClick()}}>

					<span className='material-symbols-outlined usernameIcon'>

						send

					</span>

				</div>

			</div>

		</div>
	);
}

export default NameModal;