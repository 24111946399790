import React, {useEffect, useRef} from 'react';

function ChatBox({username, socket, messages}){
	const messageContainerRef = useRef();

	const messageRef = useRef();

	const chats = messages.map((data, index) => {
		let messageName;

		if (data.sender === 'user'){
			messageName = 'message messageSelf';
		}
		else{
			messageName = 'message';
		}

		return (
			<div className={messageName} key={index}>

				<div className='messageHeader'>

					{data.name}

				</div>

				<div className='messageBody'>
	
					{data.value}

				</div>

			</div>
		);
	});

	function handleSend(){
		const message = messageRef.current.value;

		if (message){
			socket.emit('newMessage', {
				name: username.substring(0, 15),
				value: message.substring(0, 100)
			});
		}

		messageRef.current.value = '';
	}

	useEffect(() => {
		messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
	}, [chats]);

	return (
		<div className='chatBox'>

			<div className='messageContainer' ref={messageContainerRef}>

				{
					chats
				}

			</div>

			<div className='inputBox'>

				<input className='messageInput' placeholder='Enter Your Message' maxLength='100' ref={messageRef}/>

				<div className='sendButton' onClick={() => {handleSend()}}>

					<span className='material-symbols-outlined sendButtonIcon'>

						send

					</span>

				</div>

			</div>

		</div>
	);
}

export default ChatBox;